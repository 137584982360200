import { useState, useEffect } from 'react';
import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react';
import { toast } from 'sonner';
import { Sparkles, X } from 'lucide-react';
import { motion } from 'framer-motion';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import { useProfile } from '@/hooks/use-profile';
import { Database } from '@/integrations/supabase/types';

interface SkinShopProps {
  isOpen: boolean;
  onClose: () => void;
}

interface AvatarSkin {
  id: string;
  name: string;
  description?: string;
  image_url: string;
  price: number;
  created_at: string;
}

export function SkinShop({ isOpen, onClose }: SkinShopProps) {
  const session = useSession();
  const supabase = useSupabaseClient<Database>();
  const { profile, updateProfile } = useProfile();
  const [skins, setSkins] = useState<AvatarSkin[]>([]);
  const [loading, setLoading] = useState(true);
  const [equipping, setEquipping] = useState<string | null>(null);

  // Carregar skins disponíveis
  useEffect(() => {
    if (isOpen) {
      loadSkins();
    }
  }, [isOpen]);

  const loadSkins = async () => {
    try {
      const { data } = await supabase
        .from('avatar_skins')
        .select('*')
        .order('price', { ascending: true });

      if (data) {
        setSkins(data);
      }
    } catch (error) {
      console.error('Erro ao carregar skins:', error);
    } finally {
      setLoading(false);
    }
  };

  // Equipar skin
  const equipSkin = async (skinId: string) => {
    if (!session?.user?.id) return;

    try {
      setEquipping(skinId);
      await updateProfile({ equipped_skin_id: skinId });
      toast.success('Skin equipada com sucesso!');
    } catch (error) {
      console.error('Erro ao equipar skin:', error);
      toast.error('Erro ao equipar skin');
    } finally {
      setEquipping(null);
    }
  };

  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="relative w-full max-w-lg p-4"
        onClick={e => e.stopPropagation()}
      >
        <Card className="bg-[#000510] border-white/20">
          <button
            onClick={onClose}
            type="button"
            aria-label="Fechar loja"
            className="absolute -top-2 -right-2 p-2 text-white/80 hover:text-white transition-colors bg-[#000510] rounded-full border border-white/20 z-10"
          >
            <X className="w-5 h-5" />
          </button>

          <CardHeader>
            <CardTitle className="text-2xl text-white flex items-center gap-2">
              <Sparkles className="w-6 h-6 text-yellow-500" />
              Loja de Skins
            </CardTitle>
            <CardDescription className="text-white/80">
              Personalize seu avatar com skins exclusivas
            </CardDescription>
          </CardHeader>

          <CardContent>
            <div className="grid grid-cols-3 gap-4">
              {loading ? (
                // Loading placeholders
                [...Array(6)].map((_, i) => (
                  <div key={i} className="flex flex-col items-center gap-2">
                    <div className="w-20 h-20 rounded-full bg-white/10 animate-pulse" />
                    <div className="h-4 w-16 bg-white/10 rounded animate-pulse" />
                  </div>
                ))
              ) : (
                // Lista de skins
                skins.map((skin) => (
                  <div key={skin.id} className="flex flex-col items-center gap-2">
                    <Avatar className="w-20 h-20 ring-2 ring-white/20">
                      <AvatarImage src={skin.image_url} alt={skin.name} />
                      <AvatarFallback className="bg-white/10" />
                    </Avatar>
                    <Button
                      variant="ghost"
                      disabled={equipping === skin.id}
                      onClick={() => equipSkin(skin.id)}
                      className={
                        profile?.equipped_skin_id === skin.id
                          ? "bg-blue-500/20 hover:bg-blue-500/30 text-white font-medium"
                          : "hover:bg-white/20 text-white/90"
                      }
                    >
                      {equipping === skin.id ? (
                        "Equipando..."
                      ) : profile?.equipped_skin_id === skin.id ? (
                        "Equipada"
                      ) : (
                        "Equipar"
                      )}
                    </Button>
                  </div>
                ))
              )}
            </div>
          </CardContent>
        </Card>
      </motion.div>
    </motion.div>
  );
} 