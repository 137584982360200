import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase, getRedirectUrl } from '@/integrations/supabase/client';
import { Card } from '@/components/ui/card';
import { useSession } from '@supabase/auth-helpers-react';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { toast } from 'sonner';
import { motion } from 'framer-motion';
import { Crown } from 'lucide-react';
import { Separator } from '@/components/ui/separator';

const Login = () => {
  const session = useSession();
  const navigate = useNavigate();
  const baseUrl = window.location.origin;
  const containerRef = useRef<HTMLDivElement>(null);
  const [particles] = useState(() => 
    [...Array(50)].map(() => ({
      left: `${Math.random() * 100}%`,
      top: `${Math.random() * 100}%`,
      delay: `${Math.random() * 6}s`
    }))
  );

  const [isSignUp, setIsSignUp] = useState(false);
  const [showTokenInput, setShowTokenInput] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (session) {
      navigate('/rooms', { replace: true });
    }
  }, [session, navigate]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleMouseMove = (e: MouseEvent) => {
      const { clientX, clientY } = e;
      const { width, height } = container.getBoundingClientRect();
      
      const x = (clientX - width / 2) / width;
      const y = (clientY - height / 2) / height;
      
      container.style.setProperty('--mouse-x', x.toString());
      container.style.setProperty('--mouse-y', y.toString());
    };

    container.addEventListener('mousemove', handleMouseMove);
    return () => container.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const handleTokenSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { error } = await supabase.auth.verifyOtp({
        email,
        token,
        type: 'signup'
      });

      if (error) {
        const errorMessage = error.message === 'Invalid one-time password' 
          ? 'Código de confirmação inválido'
          : error.message === 'Token has expired'
          ? 'O código de confirmação expirou'
          : 'Erro ao confirmar email';
        
        throw new Error(errorMessage);
      }

      toast.success('Email confirmado com sucesso!');
      setShowTokenInput(false);
      setIsSignUp(false);
    } catch (error: any) {
      console.error('Erro na confirmação:', error);
      toast.error(error.message || 'Erro ao confirmar email');
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data: authData, error: authError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            username,
            display_name: displayName || username
          },
          emailRedirectTo: getRedirectUrl()
        }
      });

      if (authError) throw authError;
      if (!authData.user) throw new Error('Erro ao criar usuário');

      toast.success(
        'Conta criada com sucesso!\n\nVocê pode confirmar seu email de duas formas:\n\n1. Clique no link enviado para seu email\n2. Digite o código de 6 dígitos abaixo',
        { 
          duration: 10000, // 10 segundos
          style: { 
            whiteSpace: 'pre-line',
            lineHeight: '1.5'
          }
        }
      );
      setShowTokenInput(true);
    } catch (error: any) {
      console.error('Erro no cadastro:', error);
      toast.error(error.message || 'Erro ao criar conta');
    } finally {
      setLoading(false);
    }
  };

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Verificar se o input é um email ou username
      const isEmail = email.includes('@');
      let signInEmail = email;

      if (!isEmail) {
        // Buscar email pelo username
        const { data: profiles, error: profileError } = await supabase
          .from('profiles')
          .select('id')
          .eq('username', email);

        if (profileError) throw profileError;
        if (!profiles || profiles.length === 0) {
          toast.error('Usuário não encontrado');
          return;
        }

        const profile = profiles[0];

        // Buscar usuário pelo ID
        const { data: userData, error: userError } = await supabase.auth
          .admin.getUserById(profile.id);

        if (userError) throw userError;
        if (!userData?.user?.email) {
          toast.error('Erro ao buscar usuário');
          return;
        }

        signInEmail = userData.user.email;
      }

      const { error } = await supabase.auth.signInWithPassword({
        email: signInEmail,
        password
      });

      if (error) throw error;

      navigate('/rooms');
    } catch (error: any) {
      console.error('Erro no login:', error);
      toast.error(error.message || 'Email/usuário ou senha incorretos');
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      // URL de callback baseada no ambiente
      const callbackUrl = window.location.hostname === 'localhost'
        ? 'http://localhost:3001/auth/callback'
        : `${window.location.origin}/auth/callback`;

      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: callbackUrl,
          queryParams: {
            access_type: 'offline',
            prompt: 'consent',
          },
          skipBrowserRedirect: false,
        },
      });

      if (error) throw error;
    } catch (error: any) {
      console.error('Erro no login com Google:', error);
      toast.error('Erro ao fazer login com Google');
    }
  };

  return (
    <div 
      ref={containerRef}
      className="relative min-h-screen overflow-hidden nebula-bg"
    >
      {/* Círculos gradientes flutuantes */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        {[...Array(3)].map((_, i) => (
          <div
            key={i}
            className="absolute rounded-full animate-float animate-pulse-slow"
            style={{
              width: `${400 + i * 200}px`,
              height: `${400 + i * 200}px`,
              left: `${10 + i * 30}%`,
              top: `${10 + i * 20}%`,
              background: `radial-gradient(circle at center, rgba(0, 40, 85, 0.15) 0%, rgba(0, 24, 69, 0.1) 50%, transparent 70%)`,
              animationDelay: `${i * 3}s`,
              transform: `rotate(${i * 45}deg)`,
              filter: 'blur(60px)'
            }}
          />
        ))}
      </div>

      {/* Partículas brilhantes */}
      <div className="absolute inset-0 pointer-events-none opacity-30">
        {particles.map((particle, i) => (
          <div
            key={i}
            className="animate-twinkle w-1 h-1 bg-blue-400 rounded-full"
            style={{
              left: particle.left,
              top: particle.top,
              animationDelay: particle.delay
            }}
          />
        ))}
      </div>

      {/* Overlay com gradiente suave */}
      <div 
        className="absolute inset-0 pointer-events-none"
        style={{
          background: `radial-gradient(circle at calc(50% + calc(var(--mouse-x, 0) * 30%)) calc(50% + calc(var(--mouse-y, 0) * 30%)), transparent 0%, rgba(0, 18, 51, 0.3) 100%)`,
          transition: 'background 0.2s ease-out'
        }}
      />

      {/* Conteúdo principal */}
      <div className="relative z-10 min-h-screen flex flex-col items-center justify-center p-4 sm:p-8">
        {/* Cabeçalho */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-8"
        >
          <h1 className="text-5xl sm:text-6xl font-bold tracking-tighter mb-4 flex items-center justify-center gap-3">
            <Crown className="w-12 h-12 text-blue-400" />
            <span className="bg-clip-text text-transparent bg-gradient-to-b from-blue-100 via-blue-200 to-blue-600/40">
              Daminha
            </span>
          </h1>
          
        </motion.div>

        {/* Card de Login/Cadastro */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="w-full max-w-md mx-auto"
        >
          <Card className="p-6 space-y-8 bg-zinc-900/40 backdrop-blur-md border-zinc-800/50 shadow-xl rounded-xl">
            {showTokenInput ? (
              <>
                <div className="text-center space-y-4">
                  <h2 className="text-3xl font-bold text-white/90">
                    Confirme seu email
                  </h2>
                  <p className="text-blue-200/60 max-w-sm mx-auto">
                    Digite o código de 6 dígitos que enviamos para<br/>
                    <span className="text-white/90">{email}</span>
                  </p>
                </div>

                <form onSubmit={handleTokenSubmit} className="space-y-6">
                  <div className="space-y-4">
                    <Input
                      id="token"
                      placeholder="000000"
                      value={token}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, '');
                        if (value.length <= 6) setToken(value);
                      }}
                      className="bg-zinc-800/50 border-zinc-700/50 text-white placeholder:text-zinc-500 
                                 text-center text-4xl tracking-[1em] h-16 font-mono"
                      maxLength={6}
                      required
                      inputMode="numeric"
                      pattern="[0-9]*"
                      autoComplete="one-time-code"
                    />
                    <p className="text-xs text-blue-200/60 text-center">
                      Não recebeu o código? Verifique sua pasta de spam
                    </p>
                  </div>

                  <Button
                    type="submit"
                    className="w-full bg-blue-600/90 hover:bg-blue-700/90 transition-colors h-12 text-lg"
                    disabled={loading || token.length !== 6}
                  >
                    {loading ? "Verificando..." : "Confirmar email"}
                  </Button>

                  <div className="text-center space-y-2">
                    <button
                      onClick={() => {
                        setShowTokenInput(false);
                        setIsSignUp(false);
                      }}
                      type="button"
                      className="text-sm text-blue-200/60 hover:text-blue-200/90 transition-colors"
                    >
                      Voltar para login
                    </button>
                  </div>
                </form>
              </>
            ) : (
              <>
                <div className="text-center space-y-2">
                  <h2 className="text-2xl font-bold text-white/90">
                    {isSignUp ? 'Crie sua conta' : 'Bem-vindo de volta'}
                  </h2>
                  <p className="text-blue-200/60">
                    {isSignUp ? 'Comece sua jornada agora' : 'Continue sua jornada'}
                  </p>
                </div>

                {/* Botão do Google */}
                <Button
                  type="button"
                  variant="outline"
                  className="w-full bg-white hover:bg-gray-50 text-gray-900 flex items-center justify-center gap-2 py-5"
                  onClick={handleGoogleSignIn}
                  disabled={loading}
                >
                  <img src="/google.svg" alt="Google" className="w-5 h-5" />
                  Continuar com Google
                </Button>

                <div className="relative flex items-center justify-center gap-3 text-xs uppercase my-6">
                  <div className="h-[1px] flex-1 bg-zinc-700/50" />
                  <span className="text-zinc-400 whitespace-nowrap px-2">ou continue com email</span>
                  <div className="h-[1px] flex-1 bg-zinc-700/50" />
                </div>

                <form onSubmit={isSignUp ? handleSignUp : handleSignIn} className="space-y-4">
                  {isSignUp && (
                    <>
                      <div className="space-y-2">
                        <Label htmlFor="username" className="text-white/80">Nome de usuário</Label>
                        <Input
                          id="username"
                          placeholder="usuario123"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          autoComplete="username"
                          className="bg-zinc-800/50 border-zinc-700/50 text-white placeholder:text-zinc-400"
                          required
                        />
                        <p className="text-xs text-blue-200/60">
                          Nome único para identificar você no jogo
                        </p>
                      </div>

                      <div className="space-y-2">
                        <Label htmlFor="displayName" className="text-white/80">Nome de exibição (opcional)</Label>
                        <Input
                          id="displayName"
                          placeholder="Seu nome"
                          value={displayName}
                          onChange={(e) => setDisplayName(e.target.value)}
                          autoComplete="name"
                          className="bg-zinc-800/50 border-zinc-700/50 text-white placeholder:text-zinc-400"
                        />
                      </div>
                    </>
                  )}

                  <div className="space-y-2">
                    <Label htmlFor="email" className="text-white/80">
                      {isSignUp ? 'Email' : 'Email ou usuário'}
                    </Label>
                    <Input
                      id="email"
                      type={isSignUp ? "email" : "text"}
                      placeholder={isSignUp ? "email@exemplo.com" : "email@exemplo.com ou usuario123"}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete={isSignUp ? "email" : "username"}
                      className="bg-zinc-800/50 border-zinc-700/50 text-white placeholder:text-zinc-400"
                      required
                    />
                  </div>

                  <div className="space-y-2">
                    <Label htmlFor="password" className="text-white/80">Senha</Label>
                    <Input
                      id="password"
                      type="password"
                      placeholder="••••••••"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete={isSignUp ? "new-password" : "current-password"}
                      className="bg-zinc-800/50 border-zinc-700/50 text-white placeholder:text-zinc-400"
                      required
                    />
                  </div>

                  <Button
                    type="submit"
                    className="w-full bg-blue-600/90 hover:bg-blue-700/90 transition-colors"
                    disabled={loading}
                  >
                    {loading ? (
                      "Carregando..."
                    ) : isSignUp ? (
                      "Criar conta"
                    ) : (
                      "Entrar"
                    )}
                  </Button>
                </form>

                <div className="text-center">
                  <button
                    onClick={() => setIsSignUp(!isSignUp)}
                    type="button"
                    className="text-sm text-blue-200/60 hover:text-blue-200/90 transition-colors"
                  >
                    {isSignUp ? (
                      "Já tem uma conta? Entre"
                    ) : (
                      "Não tem uma conta? Cadastre-se"
                    )}
                  </button>
                </div>
              </>
            )}
          </Card>
        </motion.div>
      </div>

      {/* Vinheta suave nas bordas */}
      <div className="absolute inset-0 pointer-events-none"
           style={{
             boxShadow: 'inset 0 0 200px rgba(0,24,69,0.7)'
           }} 
      />
    </div>
  );
};

export default Login;