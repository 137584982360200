import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { supabase } from '@/integrations/supabase/client';
import { Loader2 } from 'lucide-react';
import { toast } from '@/hooks/use-toast';

export default function EmailConfirmation() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        // O Supabase já vai processar automaticamente o token da URL
        const { data: { session }, error } = await supabase.auth.getSession();

        if (error) throw error;

        if (session) {
          toast.success('Email confirmado com sucesso!');
          navigate('/rooms', { replace: true });
        } else {
          navigate('/login', { replace: true });
        }
      } catch (error: any) {
        console.error('Erro na confirmação de email:', error);
        setError(error.message);
        toast.error('Erro ao confirmar email');
      }
    };

    confirmEmail();
  }, [navigate]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-[#000510] to-[#001233]">
        <div className="text-center">
          <p className="text-red-400 mb-2">Erro na confirmação de email</p>
          <p className="text-white/80 text-sm">{error}</p>
          <button
            onClick={() => navigate('/login')}
            className="mt-4 text-blue-400 hover:text-blue-300 transition-colors"
          >
            Voltar para login
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-[#000510] to-[#001233]">
      <div className="text-center">
        <Loader2 className="w-8 h-8 animate-spin text-white mx-auto mb-4" />
        <p className="text-white/80">Confirmando seu email...</p>
      </div>
    </div>
  );
} 