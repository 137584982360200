import { createRoot } from 'react-dom/client'
import App from './App.tsx'
import './index.css'

// Renderiza a aplicação primeiro
createRoot(document.getElementById("root")!).render(<App />);

// Registra o Service Worker depois do carregamento inicial
if ('serviceWorker' in navigator) {
  const registerServiceWorker = () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('Service Worker registrado com sucesso:', registration.scope);
      })
      .catch(error => {
        console.log('Falha ao registrar o Service Worker:', error);
      });
  };

  // Usa requestIdleCallback para registrar o SW quando o navegador estiver ocioso
  if ('requestIdleCallback' in window) {
    window.requestIdleCallback(registerServiceWorker);
  } else {
    window.setTimeout(registerServiceWorker, 2000);
  }
}
