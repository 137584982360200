import { useState, useEffect } from 'react';
import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react';
import { toast } from 'sonner';
import { Trophy, Target, Swords, X } from 'lucide-react';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { generateGradientAvatar } from '@/lib/avatar';
import { Database } from '@/integrations/supabase/types';

type Profile = Database['public']['Tables']['profiles']['Row'];

interface ProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function ProfileModal({ isOpen, onClose }: ProfileModalProps) {
  const session = useSession();
  const supabase = useSupabaseClient<Database>();
  
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [stats, setStats] = useState<any>(null);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  
  // Form states
  const [username, setUsername] = useState('');
  const [displayName, setDisplayName] = useState('');

  // Carregar perfil e estatísticas
  useEffect(() => {
    if (!session?.user?.id) return;

    const loadProfileAndStats = async () => {
      try {
        // Carregar perfil e estatísticas em paralelo
        const [profileResponse, statsResponse] = await Promise.all([
          supabase
            .from('profiles')
            .select(`
              *,
              avatar_skins (
                image_url
              )
            `)
            .eq('id', session.user.id)
            .single(),
          supabase
            .from('game_stats')
            .select('*')
            .eq('user_id', session.user.id)
            .maybeSingle()
        ]);

        if (profileResponse.error) throw profileResponse.error;

        const profileData = profileResponse.data;
        if (profileData) {
          setProfile(profileData);
          setUsername(profileData.username || '');
          setDisplayName(profileData.display_name || '');

          // Definir avatar
          if (profileData.equipped_skin_id && profileData.avatar_skins?.image_url) {
            setAvatarUrl(profileData.avatar_skins.image_url);
          } else {
            setAvatarUrl(generateGradientAvatar(profileData.username || session.user.email || 'A'));
          }
        }

        // Se não existir estatísticas, criar um registro inicial
        if (!statsResponse.data) {
          const { data: newStats, error: createStatsError } = await supabase
            .from('game_stats')
            .insert({
              user_id: session.user.id,
              wins: 0,
              losses: 0
            })
            .select()
            .single();

          if (createStatsError) throw createStatsError;
          setStats(newStats);
        } else {
          setStats(statsResponse.data);
        }
      } catch (error) {
        console.error('Erro ao carregar perfil:', error);
        toast.error('Erro ao carregar dados do perfil');
      } finally {
        setLoading(false);
      }
    };

    loadProfileAndStats();
  }, [session?.user?.id, session?.user?.email]);

  const handleUpdateProfile = async () => {
    if (!session?.user?.id) return;

    try {
      setSaving(true);
      const { error } = await supabase
        .from('profiles')
        .update({
          username,
          display_name: displayName,
          updated_at: new Date().toISOString(),
        })
        .eq('id', session.user.id);

      if (error) throw error;
      toast.success('Perfil atualizado com sucesso!');
      onClose();
    } catch (error) {
      console.error('Erro ao atualizar perfil:', error);
      toast.error('Erro ao atualizar perfil');
    } finally {
      setSaving(false);
    }
  };

  if (!isOpen) return null;

  const winRate = stats ? Math.round((stats.wins / (stats.wins + stats.losses)) * 100) : 0;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="relative w-full max-w-md p-4"
        onClick={e => e.stopPropagation()}
      >
        {loading ? (
          <Card className="bg-[#000510] border-white/20">
            <CardHeader className="flex flex-row items-center gap-4 pb-2">
              <div className="w-20 h-20 rounded-full bg-white/10 animate-pulse" />
              <div className="flex-1 space-y-2">
                <div className="h-5 bg-white/10 rounded animate-pulse" />
                <div className="h-4 bg-white/10 rounded w-2/3 animate-pulse" />
              </div>
            </CardHeader>
          </Card>
        ) : (
          <Card className="bg-[#000510] border-white/20">
            <button
              onClick={onClose}
              type="button"
              aria-label="Fechar modal de perfil"
              className="absolute -top-2 -right-2 p-2 text-white/80 hover:text-white transition-colors bg-[#000510] rounded-full border border-white/20 z-10"
            >
              <X className="w-5 h-5" />
            </button>

            <CardHeader className="flex flex-row items-center gap-4 pb-4">
              <Avatar className="w-20 h-20 ring-4 ring-white/20">
                <AvatarImage 
                  src={avatarUrl || undefined}
                  alt={profile?.display_name || profile?.username}
                />
                <AvatarFallback className="bg-white/10 text-white text-2xl">
                  {profile?.display_name?.charAt(0) || profile?.username?.charAt(0) || '?'}
                </AvatarFallback>
              </Avatar>
              <div className="flex-1">
                <CardTitle className="text-2xl text-white">
                  {profile?.display_name || profile?.username || 'Jogador'}
                </CardTitle>
                <CardDescription className="text-white/80">
                  {session?.user?.email}
                </CardDescription>
              </div>
            </CardHeader>

            <CardContent className="space-y-6">
              {/* Estatísticas */}
              <div className="flex items-center justify-between border-t border-white/10 pt-4">
                <div className="flex items-center gap-2">
                  <Trophy className="w-5 h-5 text-yellow-500" />
                  <div>
                    <div className="text-sm text-white/60">Vitórias</div>
                    <div className="text-lg font-bold text-white">{stats?.wins || 0}</div>
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <Target className="w-5 h-5 text-blue-500" />
                  <div>
                    <div className="text-sm text-white/60">Taxa de Vitória</div>
                    <div className="text-lg font-bold text-white">{winRate}%</div>
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <Swords className="w-5 h-5 text-red-500" />
                  <div>
                    <div className="text-sm text-white/60">Partidas</div>
                    <div className="text-lg font-bold text-white">{stats ? stats.wins + stats.losses : 0}</div>
                  </div>
                </div>
              </div>

              {/* Configurações do Perfil */}
              <div className="space-y-4 border-t border-white/10 pt-4">
                <div className="space-y-2">
                  <Label htmlFor="username" className="text-white">Nome de usuário</Label>
                  <Input
                    id="username"
                    placeholder="usuario123"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="bg-white/10 border-white/20 text-white placeholder:text-white/50"
                  />
                  <p className="text-xs text-white/60">
                    Nome único para identificar você no jogo
                  </p>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="displayName" className="text-white">Nome de exibição</Label>
                  <Input
                    id="displayName"
                    placeholder="Seu nome"
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                    className="bg-white/10 border-white/20 text-white placeholder:text-white/50"
                  />
                </div>

                <div className="flex justify-end pt-2">
                  <Button 
                    onClick={handleUpdateProfile}
                    disabled={saving}
                    className="bg-blue-500 hover:bg-blue-600 text-white"
                  >
                    {saving ? 'Salvando...' : 'Salvar Alterações'}
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        )}
      </motion.div>
    </motion.div>
  );
} 