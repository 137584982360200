import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/integrations/supabase/client';
import { Loader2 } from 'lucide-react';

export default function AuthCallback() {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  const initializeUserProfile = async (userId: string, email: string, userData: any) => {
    try {
      // Verificar se o perfil já existe
      const { data: existingProfile } = await supabase
        .from('profiles')
        .select('username')
        .eq('id', userId)
        .single();

      // Se o perfil não existir, criar um novo
      if (!existingProfile) {
        const baseUsername = email.split('@')[0].toLowerCase();
        const randomSuffix = Math.floor(Math.random() * 1000);
        const username = `${baseUsername}${randomSuffix}`;

        // Criar perfil
        const { error: createProfileError } = await supabase
          .from('profiles')
          .insert({
            id: userId,
            username,
            display_name: userData?.user_metadata?.full_name || baseUsername
          });

        if (createProfileError) throw createProfileError;

        // Criar registro inicial de game_stats
        const { error: createStatsError } = await supabase
          .from('game_stats')
          .insert({
            user_id: userId,
            wins: 0,
            losses: 0
          });

        if (createStatsError) throw createStatsError;
      }

      return true;
    } catch (error: any) {
      console.error('Erro ao inicializar perfil:', error);
      throw error;
    }
  };

  useEffect(() => {
    const handleAuth = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        
        if (!session) {
          navigate('/login', { replace: true });
          return;
        }

        await initializeUserProfile(
          session.user.id, 
          session.user.email || '', 
          session.user
        );
        
        navigate('/rooms', { replace: true });
      } catch (error: any) {
        console.error('Erro na autenticação:', error);
        setError(error.message);
        navigate('/login', { replace: true });
      }
    };

    handleAuth();
  }, [navigate]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-[#000510] to-[#001233]">
        <div className="text-center">
          <p className="text-red-400 mb-2">Erro na autenticação</p>
          <p className="text-white/80 text-sm">Redirecionando para login...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-[#000510] to-[#001233]">
      <div className="text-center">
        <Loader2 className="w-8 h-8 animate-spin text-white mx-auto mb-4" />
        <p className="text-white/80">Autenticando...</p>
      </div>
    </div>
  );
} 