import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react';
import { Crown, LogOut, Settings, User } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { useProfile } from '@/hooks/use-profile';
import { generateGradientAvatar } from '@/lib/avatar';
import { ProfileModal } from './ProfileModal';
import { toast } from 'sonner';

export function Header() {
  const navigate = useNavigate();
  const session = useSession();
  const supabase = useSupabaseClient();
  const { profile, loading } = useProfile();
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  // Gerar URL do avatar
  const avatarUrl = profile?.equipped_skin_id && profile.avatar_skin?.image_url
    ? profile.avatar_skin.image_url
    : generateGradientAvatar(profile?.display_name || profile?.username || session?.user?.email || 'A');

  const handleSignOut = async () => {
    try {
      // Primeiro, limpar a sessão local
      const supabaseKey = 'sb-' + import.meta.env.VITE_SUPABASE_URL.split('//')[1] + '-auth-token';
      localStorage.removeItem(supabaseKey);

      // Tentar fazer logout no Supabase
      await supabase.auth.signOut();

      // Forçar atualização da página para limpar o estado da aplicação
      window.location.href = '/';
    } catch (error: any) {
      console.error('Erro ao fazer logout:', error);
      toast.error('Erro ao fazer logout');
      // Em caso de erro, forçar redirecionamento mesmo assim
      window.location.href = '/';
    }
  };

  return (
    <header className="w-full border-b border-white/5 bg-gradient-to-b from-[#000510] to-[#001233] z-50">
      <div className="container flex h-16 items-center justify-between px-4 max-w-[1600px] mx-auto">
        <div className="flex items-center gap-3">
          <Button
            variant="ghost"
            className="flex items-center gap-2 text-xl font-bold text-white hover:bg-transparent px-3"
            onClick={() => navigate('/')}
          >
            <Crown className="w-10 h-10 text-blue-400" />
            <span className="bg-gradient-to-r from-white to-white/80 bg-clip-text text-transparent">
              Daminha
            </span>
          </Button>
        </div>

        <div className="flex items-center gap-2">
          {session && profile && !loading ? (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="relative h-9 w-9 rounded-full">
                  <Avatar className="h-9 w-9 rounded-full ring-1 ring-white/10 transition-all hover:ring-white/20">
                    <AvatarImage 
                      src={avatarUrl}
                      alt={profile.display_name || profile.username}
                    />
                    <AvatarFallback className="bg-white/5 text-white text-sm">
                      {profile.display_name?.charAt(0) || profile.username?.charAt(0) || '?'}
                    </AvatarFallback>
                  </Avatar>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-56 bg-[#000510]/90 backdrop-blur-md border-white/10 text-white">
                <DropdownMenuLabel className="font-normal">
                  <div className="flex flex-col space-y-1">
                    <p className="text-sm font-medium leading-none">{profile.display_name || profile.username}</p>
                    <p className="text-xs leading-none text-white/70">{session.user.email}</p>
                  </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator className="bg-white/10" />
                <DropdownMenuItem
                  className="text-white/90 focus:bg-white/10 focus:text-white cursor-pointer"
                  onClick={() => setIsProfileOpen(true)}
                >
                  <Settings className="mr-2 h-4 w-4" />
                  <span>Configurações</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="text-white/90 focus:bg-white/10 focus:text-white cursor-pointer"
                  onClick={handleSignOut}
                >
                  <LogOut className="mr-2 h-4 w-4" />
                  <span>Sair</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <Button
              variant="ghost"
              className="text-white hover:bg-white/5 flex items-center gap-2"
              onClick={() => navigate('/login')}
            >
              <User className="w-5 h-5" />
              <span>Entrar</span>
            </Button>
          )}
        </div>
      </div>

      {isProfileOpen && (
        <ProfileModal 
          isOpen={isProfileOpen} 
          onClose={() => setIsProfileOpen(false)} 
        />
      )}
    </header>
  );
} 