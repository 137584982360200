import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useRooms } from '@/hooks/use-rooms';
import { Loader2, Users, Crown, RefreshCw, Trophy, X, Clock, Sparkles } from 'lucide-react';
import { useSession } from '@supabase/auth-helpers-react';
import { useIsMobile } from '@/hooks/use-mobile';
import { useUserProfile } from '@/hooks/use-user-profile';
import { useProfile } from '@/hooks/use-profile';
import { supabase } from '@/integrations/supabase/client';
import '../styles/ranking.css';
import { PlayerProfileCard } from '@/components/PlayerProfileCard';
import { SkinShop } from '@/components/SkinShop';
import { generateGradientAvatar } from '@/lib/avatar';
import { throttle } from 'lodash/throttle';

interface DailyRanking {
  user_id: string;
  wins: number;
  username: string;
  display_name: string | null;
  equipped_skin_id: string | null;
  avatar_skin: {
    image_url: string;
  } | null;
}

const RoomCard = ({ room }: { room: any }) => {
  const { profile } = useUserProfile(room.player1_id);
  const navigate = useNavigate();

  const handleJoinRoom = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`/room/${room.room_id}`);
  };

  const getDisplayName = () => {
    if (!profile) return room.player1_name;
    return profile.display_name || `@${profile.username}` || room.player1_name;
  };

  return (
    <Card
      className="p-4 sm:p-6 bg-zinc-800/50 border-zinc-700 hover:bg-zinc-800/80 transition-colors cursor-pointer"
      onClick={handleJoinRoom}
    >
      <div className="flex justify-between items-start mb-4">
        <h2 className="text-lg sm:text-xl font-semibold text-white">
          Sala {room.room_id}
        </h2>
        <div className="flex items-center gap-2 text-zinc-400">
          <Users className="w-4 h-4" />
          <span>1/2</span>
        </div>
      </div>

      <div className="text-sm sm:text-base text-zinc-400 mb-4">
        Jogando com: {getDisplayName()}
      </div>

      <div className="mt-4 flex justify-between items-center">
        <span className="text-xs sm:text-sm text-zinc-500">
          {new Date(room.created_at).toLocaleString()}
        </span>
        <Button
          className="bg-blue-600 hover:bg-blue-700 text-white"
          onClick={handleJoinRoom}
        >
          Entrar
        </Button>
      </div>
    </Card>
  );
};

function getNextResetTime() {
  const now = new Date();
  
  // Converte para horário de Brasília
  const brasiliaTime = new Date(now.toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }));
  
  // Próxima meia-noite
  const nextMidnight = new Date(brasiliaTime);
  nextMidnight.setHours(24, 0, 0, 0);
  
  return nextMidnight.getTime() - brasiliaTime.getTime();
}

function formatTimeLeft(ms: number) {
  const hours = Math.floor(ms / (1000 * 60 * 60));
  const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((ms % (1000 * 60)) / 1000);
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export default function Rooms() {
  const { rooms, loading, createRoom, refetch } = useRooms();
  const navigate = useNavigate();
  const session = useSession();
  const isMobile = useIsMobile();
  const { profile } = useProfile();
  const containerRef = useRef<HTMLDivElement>(null);
  const [dailyRanking, setDailyRanking] = useState<DailyRanking[]>([]);
  const [isRankingOpen, setIsRankingOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(getNextResetTime());
  const [selectedPlayer, setSelectedPlayer] = useState<string | null>(null);
  const [isShopOpen, setIsShopOpen] = useState(false);
  const [guestName, setGuestName] = useState<string | null>(localStorage.getItem('guestName'));
  const [particles] = useState(() => 
    [...Array(50)].map(() => ({
      left: `${Math.random() * 100}%`,
      top: `${Math.random() * 100}%`,
      delay: `${Math.random() * 6}s`
    }))
  );

  useEffect(() => {
    if (!session?.user && !guestName) {
      const randomNumber = Math.floor(Math.random() * 1000);
      const newGuestName = `Convidado${randomNumber}`;
      localStorage.setItem('guestName', newGuestName);
      setGuestName(newGuestName);
    }
  }, [session, guestName]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleMouseMove = throttle((e: MouseEvent) => {
      const { clientX, clientY } = e;
      const { width, height } = container.getBoundingClientRect();
      
      const x = (clientX - width / 2) / width;
      const y = (clientY - height / 2) / height;
      
      container.style.setProperty('--mouse-x', x.toString());
      container.style.setProperty('--mouse-y', y.toString());
    }, 16); // ~60fps

    container.addEventListener('mousemove', handleMouseMove);
    return () => {
      handleMouseMove.cancel();
      container.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const fetchDailyRanking = async () => {
      await supabase.rpc('check_daily_reset');
      
      const { data, error } = await supabase.rpc('get_daily_ranking');
      if (error) console.error('Erro ao buscar ranking:', error);
      else setDailyRanking(data || []);
    };

    fetchDailyRanking();
    const rankingChannel = supabase.channel('daily-ranking')
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'daily_wins'
      }, () => {
        fetchDailyRanking();
      })
      .subscribe();

    return () => {
      rankingChannel.unsubscribe();
    };
  }, []);

  const checkDailyReset = useCallback(async () => {
    await supabase.rpc('check_daily_reset');
    refetch();
    setTimeLeft(getNextResetTime());
  }, [refetch]);

  useEffect(() => {
    const timer = setInterval(() => {
      const nextReset = getNextResetTime();
      setTimeLeft(nextReset);

      if (nextReset <= 0) {
        checkDailyReset();
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [checkDailyReset]);

  const handleCreateRoom = async () => {
    const room = await createRoom();
    if (room) {
      navigate(`/room/${room.room_id}`);
    }
  };

  const getDisplayName = () => {
    if (session?.user) {
      if (!profile) return session.user.email;
      return profile.display_name || `@${profile.username}` || session.user.email;
    }
    return guestName || 'Anônimo';
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-[#000510] to-[#001233]">
        <Loader2 className="w-8 h-8 animate-spin text-white" />
      </div>
    );
  }

  return (
    <>
      {/* Botão flutuante da loja - só mostrar para usuários logados */}
      {session?.user && (
        <Button
          variant="ghost"
          size="icon"
          aria-label="Abrir loja de skins"
          className="fixed bottom-10 right-12 w-14 h-14 rounded-full bg-blue-500 hover:bg-blue-600 text-white shadow-lg hover:shadow-xl transition-all z-[100]"
          onClick={() => setIsShopOpen(true)}
        >
          <Sparkles className="w-6 h-6" />
        </Button>
      )}

      {/* Modal da loja */}
      {session?.user && <SkinShop isOpen={isShopOpen} onClose={() => setIsShopOpen(false)} />}

      <div 
        ref={containerRef}
        className="relative min-h-screen overflow-hidden bg-gradient-to-b from-[#000510] to-[#001233]"
        style={{
          perspective: '1000px',
          perspectiveOrigin: 'center'
        }}
      >
        {/* Efeitos de fundo */}
        <div className="absolute inset-0 opacity-30">
          {particles.map((particle, i) => (
            <div
              key={i}
              className="animate-twinkle w-1 h-1 bg-blue-400 rounded-full"
              style={{
                left: particle.left,
                top: particle.top,
                animationDelay: particle.delay
              }}
            />
          ))}
        </div>

        {/* Tabuleiro 3D */}
        <div 
          className="absolute inset-0 grid grid-cols-8 grid-rows-8 opacity-60"
          style={{
            transform: 'rotateX(60deg) rotateZ(calc(var(--mouse-x, 0) * 5deg)) translateZ(-100px)',
            transformStyle: 'preserve-3d',
            transition: 'transform 0.1s ease-out'
          }}
        >
          {[...Array(64)].map((_, i) => {
            const row = Math.floor(i / 8);
            const col = i % 8;
            const isBlack = (row + col) % 2 === 1;
            
            return (
              <div
                key={i}
                className={`${isBlack ? 'bg-[#001845]' : 'bg-[#002855]'} 
                           transition-all duration-300
                           hover:bg-blue-600/20`}
                style={{
                  transform: `translateZ(${Math.sin((row + col) * 0.5) * 20}px)`,
                  boxShadow: isBlack 
                    ? 'inset 0 0 30px rgba(0,0,0,0.5), 0 0 15px rgba(0,100,255,0.1)' 
                    : '0 0 15px rgba(0,100,255,0.1)'
                }}
              />
            );
          })}
        </div>

        {/* Conteúdo Principal */}
        <div className="relative z-10 flex min-h-screen">
          {/* Ranking Sidebar - mostrar para todos */}
          <aside className={`ranking-sidebar ${isRankingOpen ? 'open' : ''}`}>
            <div className="flex flex-col gap-2">
              <h2 className="ranking-title">
                <Trophy className="w-6 h-6" />
                Ranking do Dia
              </h2>

              <div className="ranking-timer">
                <p className="text-sm text-zinc-400">Próximo reset em</p>
                <p className="text-lg font-bold text-blue-400">{formatTimeLeft(timeLeft)}</p>
              </div>

              <div className="ranking-content">
                {dailyRanking.length === 0 ? (
                  <div className="ranking-empty">
                    <Trophy className="mx-auto" />
                    <p>Nenhuma vitória hoje</p>
                    <p>Seja o primeiro a vencer!</p>
                  </div>
                ) : (
                  dailyRanking.map((player, index) => {
                    const avatarUrl = player.equipped_skin_id && player.avatar_skin
                      ? player.avatar_skin.image_url
                      : generateGradientAvatar(player.username || 'A');

                    return (
                      <div 
                        key={player.user_id} 
                        className="ranking-item cursor-pointer hover:scale-[1.02] transition-transform"
                        onClick={() => setSelectedPlayer(player.user_id)}
                      >
                        <div className="ranking-position">
                          {index === 0 ? (
                            <Crown className="w-6 h-6 text-yellow-500" />
                          ) : (
                            <span>{index + 1}</span>
                          )}
                        </div>
                        <div className="ranking-avatar">
                          <img src={avatarUrl} alt={player.display_name || player.username} />
                        </div>
                        <div className="ranking-info">
                          <div className="ranking-username">
                            @{player.username}
                          </div>
                          <div className="ranking-wins">
                            <Trophy className="w-4 h-4" />
                            {player.wins} vitórias
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </aside>

          {/* Botão Mobile do Ranking - mostrar para todos */}
          {isMobile && (
            <button
              type="button"
              aria-label={isRankingOpen ? "Fechar ranking" : "Abrir ranking"}
              className="ranking-mobile-toggle"
              onClick={() => setIsRankingOpen(!isRankingOpen)}
            >
              {isRankingOpen ? (
                <X className="w-6 h-6" />
              ) : (
                <Trophy className="w-6 h-6" />
              )}
            </button>
          )}

          {/* Conteúdo das Salas */}
          <div className="flex-1 p-2 sm:p-4">
            <div className="max-w-5xl mx-auto px-4 md:mx-0 md:pl-16">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-8">
                <div>
                  <h1 className="text-2xl sm:text-3xl font-bold text-white">Salas Disponíveis</h1>
                  <p className="text-sm sm:text-base text-zinc-400 mt-2">
                    Jogando como: {getDisplayName()}
                  </p>
                </div>
                <div className="flex w-full sm:w-auto gap-2 sm:gap-4">
                  <Button
                    onClick={refetch}
                    className="flex-1 sm:flex-none bg-zinc-800/50 text-white hover:bg-zinc-700/50"
                  >
                    <RefreshCw className="w-4 h-4 mr-2" />
                    Atualizar
                  </Button>
                  <Button
                    onClick={handleCreateRoom}
                    className="flex-1 sm:flex-none bg-blue-600/80 hover:bg-blue-700/80 text-white"
                  >
                    Criar Nova Sala
                  </Button>
                </div>
              </div>

              {/* Mensagem para usuários convidados */}
              {!session?.user && (
                <div className="mb-6 p-4 bg-blue-900/20 border border-blue-500/20 rounded-lg">
                  <h2 className="text-lg font-semibold text-blue-400 mb-2">
                    Jogando como Convidado
                  </h2>
                  <p className="text-zinc-300">
                    Você está jogando no modo convidado. Para ter acesso a todas as funcionalidades como ranking, skins personalizadas e estatísticas, faça login ou crie uma conta.
                  </p>
                  <Button
                    onClick={() => navigate('/login')}
                    className="mt-4 bg-blue-600 hover:bg-blue-700 text-white"
                  >
                    Fazer Login / Criar Conta
                  </Button>
                </div>
              )}

              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6">
                {rooms.map((room) => (
                  <RoomCard key={room.room_id} room={room} />
                ))}
              </div>
            </div>
          </div>

          {selectedPlayer && (
            <PlayerProfileCard
              userId={selectedPlayer}
              isOpen={true}
              onClose={() => setSelectedPlayer(null)}
            />
          )}
        </div>

        {/* Vinheta */}
        <div className="absolute inset-0 pointer-events-none"
             style={{
               boxShadow: 'inset 0 0 200px rgba(0,24,69,0.9)'
             }} 
        />
      </div>
    </>
  );
} 