import { lazy, Suspense } from 'react';

// Componente de loading mais leve
const LoadingSpinner = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-gradient-to-b from-[#000510] to-[#001233]">
    <div className="w-8 h-8 border-2 border-blue-500 border-t-transparent rounded-full animate-spin" />
  </div>
);

// Lazy load do conteúdo principal do SplashScreen
const SplashContent = lazy(() => import('@/components/SplashContent'));

export function SplashScreen() {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <SplashContent />
    </Suspense>
  );
}

export default SplashScreen; 