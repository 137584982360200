import { useState, useEffect } from 'react';

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

export function InstallButton() {
  const [podeInstalar, setPodeInstalar] = useState(false);
  const [promptInstalar, setPromptInstalar] = useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setPromptInstalar(e as BeforeInstallPromptEvent);
      setPodeInstalar(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Verifica se já está instalado
    window.addEventListener('appinstalled', () => {
      setPodeInstalar(false);
      console.log('PWA instalado com sucesso!');
    });

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const instalarApp = async () => {
    if (!promptInstalar) return;

    try {
      await promptInstalar.prompt();
      const choice = await promptInstalar.userChoice;
      
      if (choice.outcome === 'accepted') {
        console.log('Usuário aceitou a instalação');
      } else {
        console.log('Usuário recusou a instalação');
      }
      
      setPromptInstalar(null);
      setPodeInstalar(false);
    } catch (error) {
      console.error('Erro ao instalar:', error);
    }
  };

  if (!podeInstalar) return null;

  return (
    <button
      onClick={instalarApp}
      className="fixed bottom-4 right-4 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-lg flex items-center space-x-2 transition-all duration-300 ease-in-out transform hover:scale-105"
    >
      <svg
        className="w-6 h-6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
      </svg>
      <span>Instalar Daminha</span>
    </button>
  );
} 